import React from 'react';
import Layout from '../../components/common/Layout';
import Intro from '../../components/landing/Intro';
import Features from '../../components/landing/Features';
import Testimonials from '../../components/landing/Testimonials';
import CallToAction from '../../components/landing/CallToAction';
import News from '../../components/landing/News';
import Faq from '../../components/landing/Faq';
import SEO from '../../components/SEO';
import GlobalNotice from '../../components/landing/GlobalNotice';

const IndexPage = () => (
  <Layout lang="en">
    <SEO
      tite="Nextcloud Hosting | Made in Germany | Specialised since 2012"
      description="Your own Nextcloud installation, hosted in Germany. Your digital home for your data, photos, contacts, calendar and much more."
    />
    <GlobalNotice lang="en" />
    <Intro lang="en" />
    <Features lang="en" />
    <Testimonials lang="en" />
    <CallToAction lang="en" />
    <News lang="en" />
    <Faq lang="en" />
  </Layout>
);

export default IndexPage;
